<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>方案历史</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="search">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchfrom" class="demo-form-inline">
                        <el-form-item label="标题">
                            <el-input v-model="searchfrom.title"  placeholder="标题"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="searchfrom.status"  placeholder="请选择">
                                <el-option  label="全部" value=""></el-option>
                                <el-option  label="正常" value="1"></el-option>
                                <el-option  label="作废" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="onSubmit" >查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger"  @click="gotoBack" >返回方案</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- <div class="searchright"><el-button size="small" type="danger" class="mybotton" @click="addshow=true">+ 添加方案</el-button></div> -->
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="id"
                    align="center"
                    label="ID"
                    width="60">
                    </el-table-column>
                    <el-table-column
                    prop="partitionTitle"
                    label="标题"
                    width="220">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    label="布置图">
                        <template slot-scope="scope">
                            <show-img  :url="scope.row.floorPlan" width="300" wid="50px"></show-img>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="status"
                    label="方案状态">
                        <template slot-scope="scope">
                            <el-switch
                            v-model="scope.row.status"
                            :active-value="1"
                            :inactive-value="2"
                            active-color="#404040"
                            inactive-color="#b8b8b8"
                            active-text=""
                            inactive-text=""
                            @change="changestatu($event,scope.row)"
                            >
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="creationTime"
                    label="添加时间">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="100"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="delbooth(scope.row)">恢复</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>

        <pop-up title="添加方案" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-input placeholder="方案标题" v-model="addfrom.title"></el-input>
                <upload-img :modeldata="addfrom" @changeurl="getimgurl" style="margin-top:15px;"></upload-img>
            </div>
        </pop-up>

        <pop-up title="编辑方案" width="26%" :isshow="editshow" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-input placeholder="方案标题" v-model="editfrom.title"></el-input>
                <upload-img :modeldata="editfrom" @changeurl="getimgurl" style="margin-top:15px;"></upload-img>
            </div>
        </pop-up>

        <pop-up :title="regiontitle+'-管理'" width="70%" :isshow="regionshow" :haveconfirm="false" @close="getclose3" @confirm="">
            <div slot="box">
                <div class="addbotton">
                    <div class="addbox">
                        <el-input size="small" v-model="addregiontitle" placeholder="区域名称"></el-input>
                    </div>
                    <el-button type="danger" size="small" class="mybotton3" @click="addregion">添加区域</el-button>
                </div>
                <div class="boothitem" v-for="(regionitem,index) in regionlist" :key="index">
                    <div class="region"><template v-if="regionitem.showedit"><el-input v-model="regionitem.boothRegion" size="mini" style="width:150px;vertical-align: middle;margin-top:-2px;"></el-input><el-button @click="editBoothRegion(regionitem)" style="line-height:100%;vertical-align: middle;padding:7px 5px; margin-left:5px;"  type="primary" size="mini">保存</el-button></template><template v-else><strong>{{regionitem.boothRegion}}</strong><i class="el-icon-edit" @click="editregion(regionitem)"></i></template><span @click="deleteregion(regionitem)" class="el-icon-delete" style="padding:10px 0px;"></span></div>
                    <div class="position">
                        <ul>
                            <li @click="addposition(regionitem)">
                                <u class="el-icon-plus"></u>
                            </li>
                            <li v-for="(positionitem,posiindex) in regionitem.boothSeatPojos">
                                <i class="el-icon-user-solid" style="color:#dadada; margin-top:8px;"></i>
                                <p style="font-weight:bold;">{{positionitem.seatNum<10?'0'+positionitem.seatNum:positionitem.seatNum}}</p>
                                <div class="posicz">
                                    <span style="background:#21d0b6;" @click="openeditposition(positionitem,regionitem,index)">编辑</span>
                                    <span style="background:#ff5973;" @click="deleteposition(positionitem,index)">删除</span>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </pop-up>

        <pop-up :title="eidtpositionnum+'号位置编辑'" width="26%" :isshow="editposition" :haveconfirm="true" @close="getclose4" @confirm="getconfirm4">
            <div slot="box">
                <div style="margin-top:15px;">
                    <el-input size="small" v-model="eidtpositionnum" style="width:150px;"></el-input> <span style="color:#ff405f;">注：位置编号请填数字</span>
                </div>
            </div>
        </pop-up>

    </div>
</template>
<script>
//import $ from 'jquery'
import serverurl from '@/api/booth'
import _api from '@/api/index'

const data={
    editpositionindex:"",//编辑位置是属于哪个区的下标
    eidtpositionnum:"",//被编辑的位置编号
    eidtpositionsta:0,//被编辑的位置状态
    eidtpositionid:"",//被编辑的位置id
    eidtregionid:"",//被编辑的位置的区域id
    addregiontitle:"",//添加区域时绑定的标题
    loading:false,
    total:10,
    pageNo:1,
    pageSize:10,
    searchfrom:{
        title:"",
        status:""
    },
    addfrom:{
        title:"",
        url:""
    },
    editfrom:{
        id:"",
        title:"",
        url:""
    },
    regionid:"",//打开区域位置的方案id
    regiontitle:"",//打开区域位置的方案标题
    list:[],
    regionlist:[],//分区列表
    addshow:false,//展示添加方案框
    editshow:false,//展示编辑方案框
    regionshow:false,//展示区域框
    editposition:false,//展示位置编辑枉
}
export default {
    
    data(){
        return data
    },
    created(){
        this.getList()
    },
    mounted(){
        
    },
    methods:{
        gotoBack(){
            this.$router.push("/booth")
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        delbooth(row){
            this.$confirm('真的确定要恢复吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _api.get(serverurl.delBooth,{id:row.id,flag:false}).then(res=>{
                    console.log(res)
                    if(res.success){
                        this.$message({
                            type: 'success',
                            message: '恢复成功!'
                        });
                        this.getList()
                    }
                }).catch(err=>{
                    this.$message.error(err)
                })
                
            }).catch(() => {        
            });
        },
        changestatu(data,row){
            console.log(row)
            _api.get(serverurl.updateBooth,{id:row.id,status:data}).then(res=>{
                if(res.success){
                    this.$message.success("操作成功！")
                    this.getList()
                }
            })
        },
        deleteregion(row){//删除区域
            this.$confirm('真的确定要删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _api.get(serverurl.delBoothRegion,{id:row.id}).then(res=>{
                    console.log(res)
                    if(res.success){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.regionshow=false
                        this.getList()
                    }
                }).catch((err) => {      
                    this.$message.error(err)  
                })
                
            });
            
        },
        addposition(row){//添加位置，快速添加自段给默认值
            //seatCount
            _api.post(serverurl.insterBoothSeat,{regionId:row.id,seatNum:row.seatCount+1,status:2}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$set(row,"seatCount",row.seatCount+1)
                    //this.$message.success("添加成功！")
                    _api.get(serverurl.boothRegionSeat,{regionId:row.id}).then(ress=>{//刷新这个区的位置列表
                        console.log(ress)
                        this.$set(row,"boothSeatPojos",ress.data)
                    })
                }
            })
        },
        editBoothRegion(row){//修改分区的名称
            //console.log(row.id)
            //console.log(row.boothRegion)
            _api.post(serverurl.editBoothRegion,{id:row.id,boothRegion:row.boothRegion}).then(res=>{
                //console.log(res)
                if(res.success){
                    this.$message.success("编辑成功！")
                    this.$set(row,"showedit",false)
                }
            })
            
        },
        editregion(row){//展示编辑区域名称
            this.$set(row,"showedit",true);
        },
        addregion(){//添加区域
            _api.post(serverurl.insterBoothRegion,{boothId:this.regionid,boothRegion:this.addregiontitle}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("添加区域成功！")
                    this.getregionList()
                }
            })
        },
        getregionList(){//获取分区列表
            _api.get(serverurl.regionList,{boothId:this.regionid}).then(res=>{
                console.log(res)
                this.regionlist=res.data
            })
        },
        getList(){
            this.loading=true
            _api.post(serverurl.delList,{pageNo:this.pageNo,pageSize:this.pageSize,partitionTitle:this.searchfrom.title,status:this.searchfrom.status}).then(res=>{
                console.log(res)
                this.loading=false
                this.total=res.data.total
                this.list=res.data.list
            })
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
        getconfirm(data){//确认添加方案
            this.addshow=false
            _api.post(serverurl.insterBooth,{partitionTitle:this.addfrom.title,floorPlan:this.addfrom.url}).then(res=>{
                //console.log(res)
                this.addfrom.title=""
                this.addfrom.url=""
                if(res.success){
                    this.$message.success("添加成功！");
                    this.getList()
                }
            })
        },
        openeditposition(row,regionrow,index){//打开位置编辑框
            this.editposition=true
            this.editpositionindex=index
            this.eidtpositionnum=row.seatNum
            this.eidtpositionsta=row.status
            this.eidtpositionid=row.id
            this.eidtregionid=regionrow.id//区域id
        },
        openregion(row){
            
            this.regionshow=true
            this.regionid=row.id
            this.regiontitle=row.partitionTitle
            this.getregionList()
            
        },
        openedit(row){
            this.editshow=true
            this.editfrom.id=row.id
            this.editfrom.title=row.partitionTitle
            this.editfrom.url=row.floorPlan
        },
        getconfirm2(data){//确认编辑方案
            _api.post(serverurl.editBooth,{id:this.editfrom.id,partitionTitle:this.editfrom.title,floorPlan:this.editfrom.url}).then(res=>{
                if(res.success){
                    this.$message.success("编辑成功！");
                    this.editfrom.id=""
                    this.editfrom.title=""
                    this.editfrom.url=""
                    this.editshow=false
                    this.getList()
                }
            })
        },
        deleteposition(row,index){
            console.log(row)
            //deletepositionindex
            _api.get(serverurl.deleteSeat,{seatId:row.id}).then(res=>{
                console.log(res)
                if(res.success){
                    _api.get(serverurl.boothRegionSeat,{regionId:this.regionlist[index].id}).then(ress=>{//刷新这个区的位置列表
                        console.log(ress)
                        this.$set(this.regionlist[index],"boothSeatPojos",ress.data)
                        this.$message.success("删除成功！");
                    })
                }
            }).catch(msg=>{
                this.$message.error(msg)
            })
        },
        getconfirm4(data){
            _api.post(serverurl.editSeat,{regionId:this.eidtregionid,id:this.eidtpositionid,status:this.eidtpositionsta,seatNum:this.eidtpositionnum}).then(res=>{
                if(res.success){
                    _api.get(serverurl.boothRegionSeat,{regionId:this.regionlist[this.editpositionindex].id}).then(ress=>{//刷新这个区的位置列表
                        console.log(ress)
                        this.$set(this.regionlist[this.editpositionindex],"boothSeatPojos",ress.data)
                        
                    })
                    this.editposition=false
                    this.$message.success("更新位置信息成功！");
                   
                    
                }
            }).catch(err=>{
                        this.$message.error(err)
                    })
        },
        getclose(data){//关闭添加框
            this.addshow=data
        },
        getclose2(data){//关闭编辑框
            this.editshow=data
        },
        getclose3(data){//关闭区域位置显示框
            this.regionshow=data
            this.regionid=""
            this.regiontitle=""
        },
        getclose4(data){
            this.editposition=false
        },
        getimgurl(data){//接收上传文件返回的图片地址
            this.addfrom.url=data
        }
    }

}
</script>
<style>
 .el-loading-spinner .path{    stroke: #ff704f;}
 .el-switch__label{color: #bdbdbd;}
 .el-switch__label.el-switch__label--right.is-active{color:  #3968d0;}
 .el-switch__label.el-switch__label--left.is-active{color: #ff704f;}
</style>
<style scoped>

@import "../../assets/css/booth.css";
 
</style>
